<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <template #overlay>
      <div class="d-flex flex-column align-items-center justify-center">
        <h2 class="mb-2">
          {{ $t('Updating...') }}
        </h2>
        <b-spinner variant="secondary" />
      </div>
    </template>
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="font-weight-bolder text-body">
          {{ $t('Primary Contact') }}
        </h4>
        <div class="d-flex align-items-center justify-content-center">
          <feather-icon
            icon="LEditIconUpdated"
            class="mr-1 cursor-pointer"
            size="34"
            @click="getCustomer.default_contact === null ? null : gotoNewPage(`/customers/${getCustomer.id}/contacts/${getCustomer.default_contact.id}/update?isEdit=true`, $event, '_blank') "
          />
          <button-dashed
            class="btnBorderRevert"
          >
            <feather-icon
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="28"
              @click="gotoNewPage(`/customers/${getCustomer.id}/contacts/create`, $event, '_blank')"
            />
          </button-dashed>
        </div>
      </div>
      <b-table
        v-if="getCustomer.default_contact"
        class="mb-1 table--custom-style"
        :fields="primaryContactFields"
        :items="[primaryContact]"
      >

        <template #cell(action)="data">
          <div>
            <feather-icon
              icon="LStockEyeIcon"
              class="ml-1 cursor-pointer"
              size="32"
              @click="gotoNewPage(`/customers/${getCustomer.id}/contacts/${data.item.id}/update`, $event, '_blank')"
            />
          </div>
        </template>

        <template #cell(name)="data">
          <div>
            {{ `${data.item.first_name }  ${data.item.last_name } ` }}
          </div>
        </template>

        <template #cell(phone)="data">
          <div v-html="getPhoneText(data.item)" />
        </template>

        <template #cell(type)="data">
          <div>
            {{ getTypeContactText(data.item) }}
          </div>
        </template>

      </b-table>
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="font-weight-bolder text-body">
          {{ $t('Primary Address') }}
        </h4>
        <div class="d-flex align-items-center justify-content-center">
          <feather-icon
            icon="LEditIconUpdated"
            class="mr-1 cursor-pointer"
            size="34"
            @click="getCustomer.default_address.id === null ? gotoNewPage(`/customers/${getCustomer.id}/addresses`, $event, '_blank') : gotoNewPage(`/customers/${getCustomer.id}/addresses/${getCustomer.default_address.id}/update?isEdit=true`, $event, '_blank')"
          />
          <button-dashed
            class="btnBorderRevert"
          >
            <feather-icon
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="28"
              @click="gotoNewPage(`/customers/${getCustomer.id}/addresses/create`, $event, '_blank')"
            />
          </button-dashed>
        </div>
      </div>
      <b-table
        v-if="getCustomer.default_address"
        class="mb-1 table--custom-style"
        :fields="primaryAddressFields"
        :items="[primaryAddress]"
      >

        <template #cell(action)="data">
          <div>
            <feather-icon
              icon="LStockEyeIcon"
              class="ml-1 cursor-pointer"
              size="32"
              @click="data.item.id ? gotoNewPage(`/customers/${getCustomer.id}/addresses/${data.item.id}/update`, $event, '_blank') : gotoNewPage(`/customers/${getCustomer.id}/addresses`, $event, '_blank')"
            />
          </div>
        </template>
        <template #cell(address)="data">
          <div>
            {{ data.item.line2 ? data.item.line2 : '---' }}
          </div>
        </template>
        <template #cell(city)="data">
          <div>
            {{ data.item.city ? data.item.city : '---' }}
          </div>
        </template>
        <template #cell(state)="data">
          <div>
            {{ getStateName(data.item) }}
          </div>
        </template>
        <template #cell(type)="data">
          <div>
            {{ getTypeAddressText(data.item) }}
          </div>
        </template>

      </b-table>
      <form-features-wrapper
        :is-calendar-button="false"
        :is-update-form="true"
        :is-editable="isEditable"
        :can-edit="ACCESS_ABILITY_FOR_ACCOUNTS"
        :is-pen-button="organizationRequiresEditConfirmation"
        @changeEditable="changeEditable"
        @trashButtonHandler="trashButtonHandler"
      >
        <error-alert
          :fields="fields"
          :error="error"
        />
        <validation-observer ref="customerCreateVal">
          <div>
            <b-row>
              <b-col md="6">
                <h4 class="font-weight-bolder text-body">
                  {{ $t('Business Information') }}
                </h4>
                <component
                  :is="fields['cust_name_dba'].type"
                  ref="refNameDba"
                  v-model="customer['cust_name_dba']"
                  v-bind="getProps('cust_name_dba')"
                  check-unique
                  :is-editable="isEditable"
                  @backResponse="checkCustomerName"
                />
                <component
                  :is="fields['is_active'].type"
                  v-model="customer['is_active']"
                  :is-editable="isEditable"
                  v-bind="getProps('is_active')"
                />
              </b-col>
              <b-col md="6">
                <div>
                  <label class="inputLabel">{{ $t('Business Type') }}</label>
                  <component
                    :is="fields[field].type"
                    v-for="field in ['allow_to_rent_from_subrent', 'allow_to_purchase_from_supplier']"
                    :key="field"
                    v-model="customer[field]"
                    :is-editable="isEditable"
                    v-bind="getProps(field)"
                  />
                </div>
                <div>
                  <!--                  <label class="font-weight-bolder mt-2">{{ $t('Customer Rules') }}</label>-->
                  <div
                    v-for="{ id, name } in customerRules"
                    :key="id + 'customerRules'"
                    class="d-flex"
                  >
                    <b-form-checkbox
                      :id="id.toString() + name"
                      type="checkbox"
                      :disabled="isEditable"
                      :checked="isChecked(id, customer.customer_rules_ids)"
                      :class="`d-flex align-items-center ${isEditable ? 'custom--checkbox--disabled' : ''}`"
                      @change="toggleIds(id, 'customer_rules_ids')"
                    />
                    <label
                      :for="id.toString() + name"
                      style="margin-top: 5px;"
                      class="ml-1"
                    >{{ name }}</label>
                  </div>
                </div>
              </b-col>
              <!--              <b-col-->
              <!--                v-for="field in ['is_active']"-->
              <!--                :key="field"-->
              <!--                md="6"-->
              <!--              >-->
              <!--                <component-->
              <!--                  :is="fields[field].type"-->
              <!--                  v-model="customer[field]"-->
              <!--                  :is-editable="isEditable"-->
              <!--                  v-bind="getProps(field)"-->
              <!--                />-->
              <!--              </b-col>-->
              <b-col
                v-for="field in ['account_manager_id']"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-model="customer[field]"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                />
              </b-col>
              <b-col
                v-for="field in ['pseudonym']"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-model="customer[field]"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                />
              </b-col>
              <!--    fields          ,'payment_method_id'-->
              <b-col
                v-for="field in ['payment_term_id']"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-if="hasKey(field)"
                  v-model="customer[field]"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                  :selected-values="returnFiledLabel(field)"
                />
              </b-col>
              <b-col
                v-for="field in ['currency_id','price_tier_id','duns_number','tax_rule_id','location_id']"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-model="customer[field]"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                />
              </b-col>

              <b-col md="6">
                <component
                  :is="fields['tags'].type"
                  v-if="hasKey('tags')"
                  v-model="customer.tags"
                  :is-editable="isEditable"
                  v-bind="getProps('tags')"
                  :selected-values="returnTags('tags')"
                />
              </b-col>
              <b-col
                v-for="field in ['delivery_type_id']"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-model="customer[field]"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <component
                  :is="fields[field].type"
                  v-for="field in ['comments']"
                  :key="field"
                  v-model="customer[field]"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                />
              </b-col>
              <b-col>
                <component
                  :is="fields[field].type"
                  v-for="field in ['ein_tax_number', 'credit_limit', 'discount']"
                  :key="field"
                  v-model="customer[field]"
                  :has-character="field === 'discount'"
                  :is-editable="isEditable"
                  v-bind="getProps(field)"
                />
              </b-col>
            </b-row>
            <b-row />
          </div>
          <portal to="body-footer">
            <div class="d-flex mt-2 pb-1 justify-content-between">
              <div>
                <b-button
                  v-if="!isFormChanged"
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="$router.push({ name: 'home-customers-list' })"
                >
                  {{ $t('Back to List') }}
                </b-button>
                <b-button
                  v-else
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="loader"
                >
                  <feather-icon
                    icon="LCancelIcon"
                    size="16"
                  />
                  {{ $t('Cancel') }}
                </b-button>
              </div>
              <div>
                <b-button
                  v-if="$can(ACCESS_ABILITY_FOR_ACCOUNTS.action, ACCESS_ABILITY_FOR_ACCOUNTS.subject)"
                  variant="success"
                  class="saveBtn font-medium-1 font-weight-bolder"
                  :disabled="!isFormChanged && !isPaymentOptionSelect || isLoading"
                  @click="submit"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  <span v-else>{{ $t('SAVE') }}</span>
                </b-button>
              </div>
            </div>
          </portal>
        </validation-observer>
        <l-modal-unique
          id="add-customer-name-unique-modal"
          ref="uniqueModal"
          :exist-customers="existCustomer"
          @ok="stayInThisPage"
          @cancel="redicrectToTheExistingCustomerUpdate"
          @hide="clearExistingCustomer"
        />
        <b-s-warning-modal ref="confirm-modal" />
      </form-features-wrapper>

    </div>
  </b-overlay>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton, BCol, BForm, BRow, BFormRadio, BModal, BFormCheckbox, BFormSelect, BSpinner, BOverlay, BTable, VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationObserver } from 'vee-validate'
import jsonToFormData from '@/libs/jsonToFormData'
import { scrollToError } from '@core/utils/utils'
import LModalUnique from '@/views/components/LModalUnique.vue'
import store from '@/store'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import SelectAccount from './SelectAccount.vue'
import config from '../../customConfig'

export default {
  name: 'UpdateGeneralInfoForm',
  components: {
    ButtonDashed,
    BTable,
    BOverlay,
    SelectAccount,
    BSpinner,
    ErrorAlert,
    BForm,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormRadio,
    BModal,
    BFormCheckbox,
    ValidationObserver,
    LModalUnique,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      isLoading: false,
      showSelectInput: true,
      onSubmit: false,
      existCustomer: [],
      paymentOptionsId: [],
      customerRulesIds: [],
      currentNameDba: null,
      selectPaymentOption: null,
      checkboxRequiredMessage: null,
      isEditable: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    getDefaultSettings() {
      return this.$store.state[this.MODULE_NAME].defaultDataCustomer
    },
    primaryContact() {
      return this.$store.state[this.MODULE_NAME].customer.default_contact
    },
    primaryAddress() {
      return this.$store.state[this.MODULE_NAME].customer.default_address
    },
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    customer() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
    customers() {
      return this.$store.state.listModule.customersList
    },
    // customerID() {
    //   return this.$store.state[this.MODULE_NAME].accountParametersForm.customer_id_3rd_party_payment_option
    // },
    getCustomer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
    customerFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].customerGeneralForm
    },
    isFormChanged() {
      return JSON.stringify(this.customer) !== this.customerFormClone
    },
    isPaymentOptionSelect() {
      // if (this.selectPaymentOption?.cust_name_dba) {
      //   return JSON.stringify(this.selectPaymentOption.cust_name_dba) !== JSON.stringify(this.getCustomer.customer_3rd_party_payment_option.cust_name_dba)
      // }
      return this.isFormChanged
    },
    defCustomer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentOptionList
    },
    customerRules() {
      return this.$store.state.listModule.customerRulesList
    },
    paymentTermsList() {
      return this.$store.state.listModule.paymentTermList
    },
  },
  created() {
    this.currentNameDba = this.defCustomer.cust_name_dba
    this.$store.dispatch('listModule/paymentTermList', { is_active: 1 })
    // this.selectPaymentOption = this.getCustomer?.customer_3rd_party_payment_option ?? null
    this.$store.dispatch('listModule/paymentOptionList')
    this.$store.dispatch('listModule/customerRulesList')
  },
  methods: {
    // selectHandler(item) {
    //   const {
    //     id, customer_detail, cust_name_dba, default_address,
    //   } = item
    //   // this.getCustomer.customer_3rd_party_payment_option = {
    //   //   id, customer_detail, cust_name_dba, default_address,
    //   // }
    // },
    getObjectIdFromArrayByName(array, name) {
      return array.find(item => item.name === name).id
    },
    changeEditable() {
      this.isEditable = !this.isEditable
    },
    trashButtonHandler() {
      const { id } = this.getCustomer
      this.confirmNotification(this, [id], `${this.MODULE_NAME}/moveToTrash`)
        .then(
          () => {
            this.$router.push({ name: 'home-customers-list' })
          },
        )
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.customerCreateVal.reset()
      const clonedData = JSON.parse(this.customerFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_ACCOUNT_PARAMETERS_FORM`, clonedData)
    },
    getNeedObj(arr, name) {
      return arr.find(item => item.name === name)
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.fields.hasOwnProperty(key)
    },
    returnTags(tagsArrName) {
      return this.getCustomer[tagsArrName]?.map(tags => tags?.label)
    },
    returnFiledLabel(key) {
      return this.getCustomer?.[key]?.map(item => item?.label)
    },
    submit() {
      // this.error = {}
      // this.onSubmit = true
      // console.log('smthis here')
      // if (!this.customer.payment_options_ids.length) {
      //   console.log(this.customer)
      //   this.checkboxRequiredMessage = this.PAYMENT_OPTIONS_REQUIRED_MESSAGE
      // } else
      // {
      this.$refs.customerCreateVal.validate()
        .then(success => {
          if (success) {
            this.isLoading = true
            const data = this.customer
            const customerId = this.getCustomer.id

            const paymentTermsIds = this.paymentTermsList?.reduce((acc, item) => {
              if (data?.payment_term_id?.includes(item.label)) {
                acc.push(item.id)
              }
              return acc
            }, [])

            const paymentMethodIds = this.paymentOptions?.reduce((acc, item) => {
              if (data?.payment_method_id?.includes(item.name)) {
                acc.push(item.id)
              }
              return acc
            }, [])

            const customer = this.mappingFields(Object.keys(this.accountParameters), data, {
              cust_name_dba: data.cust_name_dba,
              pseudonym: data.pseudonym,
              is_active: data.is_active,
              currency_id: data.currency_id.id,
              location_id: data.location_id.id,
              payment_term_id: paymentTermsIds,
              payment_method_id: paymentMethodIds,
              price_tier_id: data.price_tier_id.id,
              account_manager_id: data.account_manager_id?.id ?? null,
              delivery_type_id: data.delivery_type_id.id,
              tax_rule_id: data.tax_rule_id.id,
              duns_number: data.duns_number,
              tags: data.tags,
              payment_options_id: data.payment_options_ids,
              customer_rules_id: data.customer_rules_ids,
              comments: data.comments,
              discount: (Number(this.getDefaultSettings?.discount) > Number(data.discount)) ? this.getDefaultSettings?.discount : data.discount,
              // customer_id_3rd_party_payment_option: this.getCustomer.customer_3rd_party_payment_option?.id ?? null,
            })

            const customerFormData = jsonToFormData(customer)

            this.sendNotification(this, { customerFormData, customerId }, `${this.MODULE_NAME}/updateCustomer`)
              .then(res => {
                this.clear()
                this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
                setTimeout(() => {
                  this.$router.push({
                    name: 'home-customers-addresses',
                    params: { id: this.$route.params.id },
                  })
                }, 100)
              })
              .catch(err => {
                // this.error = err.response?.data
                this.error = true
                this.errorNotification(this, err)
              }).finally(() => {
                this.isLoading = false
                this.onSubmit = false
                this.$emit('formSubmissionComplete', this.customer)
              })
          }
          scrollToError(this, this.$refs.customerCreateVal)
        })
      // }
    },
    clear() {
      this.$refs.customerCreateVal.reset()
      // this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'home-customers' })
    },
    // eslint-disable-next-line consistent-return
    isChecked(id, listArray) {
      return Array.isArray(listArray) && listArray.includes(id)
    },
    toggleIds(id, field) {
      if (this.customer[field].includes(id)) {
        this.customer[field] = this.customer[field].filter(pId => pId !== id)
        if (field === 'payment_options_ids') {
          this.customer[field] = this.customer[field].sort((a, b) => a - b)
        }
        if (id === this.thirdPartyPaymentOptionId) {
          // eslint-disable-next-line no-unused-expressions
          this.showSelectInput ? this.showSelectInput = false : this.showSelectInput = true
        }
      } else {
        this.customer[field].push(id)
        if (field === 'payment_options_ids') {
          this.customer[field] = this.customer[field].sort((a, b) => a - b)
        }
        if (id === this.thirdPartyPaymentOptionId) {
          // eslint-disable-next-line no-unused-expressions
          this.showSelectInput ? this.showSelectInput = false : this.showSelectInput = true
        }
      }
      if (this.customer.payment_options_ids.length) {
        this.checkboxRequiredMessage = null
      }
    },
    getProps(fieldName) {
      // if (fieldName === 'customer_id_3rd_party_payment_option') {
      //   const props = {
      //     is: this.accountParameters[fieldName].type,
      //     field: { ...this.accountParameters[fieldName], rules: `${this.isChecked(this.thirdPartyPaymentOptionId, this.customers.payment_options_ids) ? 'required' : ''}` },
      //     name: fieldName,
      //   }
      //   return props
      // }
      return {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
    stayInThisPage() {
      this.$refs.uniqueModal.hide()
    },
    checkCustomerName(customer) {
      if (!this.defCustomer.id && customer.length !== 0 && typeof customer === 'object') {
        this.existCustomer = customer
        debugger
        this.$refs.uniqueModal.show()
      }
    },
    redicrectToTheExistingCustomerUpdate(id) {
      this.$router.push({ name: 'home-customers-general-info', params: { id } })
    },
    clearExistingCustomer() {
      this.existCustomer = null
    },
    getCheckboxValue(e, id, listName) {
      if (!this.customer[listName]) {
        this.$set(this.customer, listName, [])
      }
      if (e) {
        if (!this.customer[listName].includes(id)) {
          this.customer[listName].push(id)
        }
      } else {
        this.customer[listName] = this.customer[listName].filter(item => item !== id)
      }
    },
    getStateName(item) {
      if (item && item.state) {
        return item.state?.name || ''
      }
      return '---'
    },
    getTypeAddressText(item) {
      const parts = []
      if (item.billing) parts.push(this.$t('Billing'))
      if (item.shipping) parts.push(this.$t('Shipping'))
      if (item.account_holder) parts.push(this.$t('Acct. Holder'))
      return parts.length > 0 ? parts.join(', ') : '---'
    },
    makePhoneNumber(phoneDefault, obj) {
      let phoneReturn = ''
      if (obj) {
        const isObj = (typeof obj === 'object') ? obj : JSON.parse(obj)
        phoneReturn = `+${isObj?.countryCallingCode}${isObj?.formatNational}`
      } else {
        phoneReturn = phoneDefault
      }
      return phoneReturn
    },
    getPhoneText(item) {
      const phones = []
      if (item.phone_one && item.phone_one_object) {
        phones.push(`${this.makePhoneNumber(item.phone_one, item.phone_one_object)} [${typeof item.phone_one_object === 'object' ? item.phone_one_object.phone_type_val : JSON.parse(item.phone_one_object).phone_type_val}]`)
      }
      if (item.phone_two && item.phone_two_object) {
        phones.push(`${this.makePhoneNumber(item.phone_two, item.phone_two_object)} [${typeof item.phone_two_object === 'object' ? item.phone_two_object.phone_type_val : JSON.parse(item.phone_two_object).phone_type_val}]`)
      }
      if (item.phone_three && item.phone_three_object) {
        phones.push(`${this.makePhoneNumber(item.phone_three, item.phone_three_object)} [${typeof item.phone_three_object === 'object' ? item.phone_three_object.phone_type_val : JSON.parse(item.phone_three_object).phone_type_val}]`)
      }
      if (item.phone_four && item.phone_four_object) {
        phones.push(`${this.makePhoneNumber(item.phone_four, item.phone_four_object)} [${typeof item.phone_four_object === 'object' ? item.phone_four_object.phone_type_val : JSON.parse(item.phone_four_object).phone_type_val}]`)
      }

      return phones.length > 0 ? phones.join('<br>') : '---'
    },
    getTypeContactText(item) {
      const parts = []
      if (item.is_billing) parts.push(this.$t('Billing'))
      if (item.is_shipping) parts.push(this.$t('Shipping'))
      if (item.is_account_holder) parts.push(this.$t('Acct. Holder'))
      return parts.length > 0 ? parts.join(', ') : '---'
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const MODULE_NAME_CLONE = 'cloneData'
    // const isEditable = ref(true)

    const {
      primaryContactInformation,
      accountParameters,
      moreContactInformation,
      ACCESS_ABILITY_FOR_ACCOUNTS,
      PAYMENT_OPTIONS_REQUIRED_MESSAGE,
      suggestionTableColumns,
      primaryContactFields,
      primaryAddressFields,
    } = config()

    const fields = { ...accountParameters, ...primaryContactInformation, ...moreContactInformation }
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
      primaryContactFields,
      primaryAddressFields,
      accountParameters,
      primaryContactInformation,
      // isEditable,
      suggestionTableColumns,
      ACCESS_ABILITY_FOR_ACCOUNTS,
      PAYMENT_OPTIONS_REQUIRED_MESSAGE,
    }
  },
}
</script>
<style lang="scss">
.custom--checkbox--disabled {
  .custom-control-input:disabled+label::after {
    background-color: #efefef;
    border-radius: 5px;
    opacity: 0.5;
  }
}
</style>
